import React, { useEffect, useState, memo } from "react";
import PubSub from 'pubsub-js';

import Service from "../services/service";
import useSocket from "../hooks/useSocket";

import { connect } from 'react-redux';
import actions from '../redux/actions';

import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';



import Drawer from '@material-ui/core/Drawer';

import InputMask from 'react-input-mask';
import Router from "next/router";
import swal from '@sweetalert/with-react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HelpIcon from '@material-ui/icons/Help';

import { useForm, Controller, useFormContext, useController  } from "react-hook-form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  modal: {
    zIndex: theme.zIndex.modal + 1,
  }
});

/**************************************************************/
/* esse cara aqui é quando o contratante ou diarista quer ver */
/* telefones dentro plataforma - via pix */
/**************************************************************/
const Index = (props) => {

    var subscribeModal = null;
    const { control, register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm();

    const [model, setModel] = useState({...props.model});
    const [open, setOpen] = useState(props.open || false);
    const [toogles, setHandleToogles] = useState({    
        backdrop: false,
        step: 'information'
    });
    const [pix, setPix] = useState({});
    const [waiting, setAwaiting] = useState(false);

    const copyClipboardConfirmationSaldo = async (text) => {
      await Service.writeText(text);
      handleToggle('copyText', text);
    }

    useEffect(() => {

      subscribeModal = PubSub.subscribe('openFormPayment', (type, model) => {
        setOpen(true);
      });

      return () => {
        PubSub.unsubscribe(subscribeModal);
        subscribeModal = null;
      }  
    }, []);

    useEffect(() => {

      if(open && !props.user && !props.isAuthenticated){
        setOpen(false);
        PubSub.publish('openModal');
        setAwaiting(true);
      }

      if(!open && props.user && props.isAuthenticated && waiting){
        setOpen(true);
        setAwaiting(false);
      }

      if(open){
        setValue("telefones", 25);
        setValue("nome", props?.user?.nome);
        setValue("cpf", "");
        setValue("creditType", "CREDITOS");
        setValue("email", props?.user?.email);
        setValue("phone_number", props?.user?.telefoneValidado);
      }
    }, [open, props])

    const handleToggle = (name, flag) => {
      setHandleToogles((toogles) => ({...toogles, [name]: flag}));
    }

    const amount = Service.amount;
    const handleCloseNavigate = () => {
      setOpen(false);
      handleToggle('step', 'information');
    }

    const handleSubmitPropose = async (data) => {

      if(toogles.step === 'information'){
        handleToggle('step', 'formulario');
      }

      if(toogles.step === 'formulario'){

        data.cpf = data.cpf.toString().replace(/\D/g, "");

        if(!Service.validCpfCnpj(data.cpf)){
          swal('', 'cpf inválido', 'error');
          return false;
        }

        handleToggle('step', 'confirmation');
      }

      if(toogles.step === 'confirmation'){
        PubSub.publish('backdrop', true);

        data.amount = getValues("creditType") === 'GIFT' ? (getValues("telefones")*100).toFixed(2) : (getValues("telefones")*Service.PRICE_CREDITS).toFixed(2)
        data.type = 'CONTRATANTE';
        data.userId = props?.user?._id;

        Service.post(`${Service.api}/integrations/gerencianet/pixCreateCharge`, data).then(resp => {
          setPix(resp);
          handleToggle('step', 'payment');
          PubSub.publish('backdrop', false);
        })
      }
    }

    const handleUpdatePix = (transaction) => {

      if(transaction.solicitacaoPagador === props?.user?.email && transaction.status === 'CONCLUIDA'){
        
          handleToggle('step', 'completed');
          Service.scrollIntoView('__next');
          //handleCloseNavigate();
          
            swal({content: <div><p className="text-center">Obrigada, o seu PIX foi aceito!</p><h3 className="barlow fw-bold">Na Odete você pode MUITO mais!</h3></div>, icon: 'success'}).then(() => {
              props.getUser();
            });
      }
    }

    const souProfissional = () => {
      handleCloseNavigate()
      Router.push('/register')
    };

    const socket = useSocket(Service.api);

    useEffect(() => {
      if(socket && open){
        socket.on("newPIX", handleUpdatePix);
      }

      return ()=> {
        if(socket){
          socket.off("newPIX");
        }
      }
    }, [socket, open]);

    const changeType = (type) => {
      setValue("creditType", type)
      setValue("telefones", (type === 'GIFT' ? 5 : 25))
    }

    const help = (type) => {

      var helper = {
        'GIFT': {
          title: 'GIFT CARD',
          message: 'GIFT CARD É COMO SE FOSSE UM VALE PRESENTE, ONDE É GERADO UM CUPOM E VOCÊ INSERE NO FINAL DO SERVIÇO REALIZADO PELA PESSOA CONTRATADA.'
        },
        'CREDITOS': {
          title: 'CRÉDITOS',
          message: 'CRÉDITOS SÃO USADOS PARA ABRIR OS TELEFONES DE DIARISTAS E REALIZAR DISPAROS.'
        }
      }

      const h = helper[type]

      swal({content: <div className="pt-2"><p className="text-center">{h.title}</p><h6 className="barlow fw-bold">{h.message}</h6></div>, icon: 'success'});
    }

  return (<>
    {open && <Drawer anchor="bottom" open={open} onClose={handleCloseNavigate} keepMounted className={`contratatePremium formPayment`} style={{zIndex: (theme) => theme.zIndex.modal + 1}} data-component="formPayment">
      <form onSubmit={handleSubmit(handleSubmitPropose)} name="form" className="d-flex flex-column h-100 overflow-hidden">
        <DialogTitle id="alert-dialog-slide-title">
          <div className="d-flex align-items-center justify-content-between">
            <div>Odete.com.br</div>
            <IconButton aria-label="close" onClick={handleCloseNavigate}>
              <CloseIcon titleAccess="fechar modal" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Slide in={toogles.step === 'information'} mountOnEnter unmountOnExit>
            <div>
                <img src={Service.parseURLImage(props?.user)} alt="Contratante adquirindo créditos" className="rounded-circle img-thumbnail mb-2 m-auto d-flex" width="64" height="64" />
                <p className="text-center fw-bold">Olá {props?.user?.nome}, você está com {props?.user?.creditos || 0} créditos</p>
                <div className="text-center">
                  <p>Legal saber que você está sentindo confiança na plataforma!</p>
                  {
                    props?.user?.type === 'CONTRATANTE' && <>
                        <p>Adquira créditos para visualizar o telefone de profissionais diaristas.</p>
                        <p className="fw-bold barlow m-0">ou...</p>
                        <p>Publique uma oferta, funcionalidade que pode ser usada <strong>1 vez gratuitamente</strong></p>
                      </>
                  }

                  {
                    props?.user?.type !== 'CONTRATANTE' && <>
                        <p>Adquira créditos para utilizar alguns recursos extras da plataforma.</p>
                      </>
                  } 


                </div>
            </div>
          </Slide>
          <Slide direction="left" in={toogles.step === 'formulario'} mountOnEnter unmountOnExit>
            <div>
              <fieldset>
                <legend className="barlow fs-6 fw-bold text-uppercase">Que tipo de crédito você deseja?</legend>
                <div className="d-flex gap-3 mb-3">
                  <div className="w-100">
                      <div className="form-check d-flex align-items-center gap-2">
                        <input onChange={() => changeType('CREDITOS')} checked={getValues("creditType") === 'CREDITOS'} value="CREDITOS"  className="form-check-input" type="radio" id="CREDITS" />
                        <label className="form-check-label d-flex align-items-center" htmlFor="CREDITS">
                          CRÉDITOS <IconButton onClick={() => help('CREDITOS')}><HelpIcon /></IconButton>
                        </label>
                      </div>
                      <small>Acesso aos telefones de profissionais diaristas e realizar disparos e outras funcionalidades</small>
                    </div>


                  <div className="w-100">
                    <div className="form-check d-flex align-items-center gap-2">
                      <input onChange={() => changeType('GIFT')} checked={getValues("creditType") === 'GIFT'} value="GIFT" className="form-check-input" type="radio" id="GIFT" />
                      <label className="form-check-label d-flex align-items-center" htmlFor="GIFT">
                        GIFT CARD <IconButton onClick={() => help('GIFT')}><HelpIcon /></IconButton>
                      </label>
                    </div>
                    <small>Para efetuar pagamento para profissionais diaristas</small>
                  </div>

                </div>
                <hr />
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="credits">Quantos créditos você deseja adquirir?</label>
                    <input
                      type="number"
                      autoFocus
                      placeholder="XXX"
                      min={watch("creditType") === 'GIFT' ? 5 : 25}
                      id="credits"
                      max={500}
                      required={true}
                      className="form-control"
                      {...register("telefones")} 
                    />
                    <small className="form-text">Mínimo {watch("creditType") === 'GIFT' ? 5 : 25} # Máximo 500</small>
                </div>
                {watch("creditType") === 'GIFT' ? 
                <ul>
                  <li>1 GIFT {'=>'} R$ 100,00;</li>
                </ul>: <ul>
                  <li>1 CRÉDITO {'=>'} {Service.formatCurrency(Service.PRICE_CREDITS)};</li>
                  <li>{Service.CREDIT_BY_TELEFONE} créditos por telefone visualizado;</li>
                  <li>{Service.CREDIT_BY_DISPARO} créditos por disparo realizado;</li>
                </ul>}
             </fieldset>

              <fieldset className="">
                <legend className="barlow fs-6 fw-bold text-uppercase">Dados do Pagador</legend>
                <div className="row">                    
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="document">CPF</label>
                      <InputMask
                        {...register("cpf")}
                        type="tel"
                        id="document"
                        mask="999.999.999-99"
                        placeholder="XXX.XXX.XXX-XX"
                        required={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone_number">
                        Telefone
                      </label>
                      <InputMask
                        {...register("phone_number")}
                        type="text"
                        id="phone_number"
                        placeholder="Telefone"
                        mask="(99) 99999-9999"
                        className="form-control"
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-12">
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="name">Nome</label>
                      <input
                        {...register("nome")}
                        type="text"
                        id="name"
                        placeholder="Nome"
                        required={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="email">E-mail</label>
                      <input
                        {...register("email")}
                        type="email"
                        id="email"
                        placeholder="E-mail"
                        required={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </Slide>
          <Slide direction="left" in={toogles.step === 'confirmation'} mountOnEnter unmountOnExit>
            <div className="text-center">
              <p className="text-success"><VerifiedUserIcon fontSize="large" /></p>
              {getValues("creditType") === 'GIFT' ? <p>Olá {getValues("nome")}, você está adquirindo {getValues("telefones")} GIFT CARDS por: <strong>{Service.formatCurrency(getValues("telefones")*100)}</strong></p> : <p>Olá {getValues("nome")}, você está adquirindo {getValues("telefones")} créditos por: <strong>{Service.formatCurrency(getValues("telefones")*Service.PRICE_CREDITS)}</strong></p>}
              <p className="fw-bold barlow lead">Será gerado um PIX e quando efetuado o pagamento, o acesso será liberado automaticamente.</p>
              <div className="alert alert-light">
                <p><strong className="fw-bold badge bg-danger text-uppercase">Atenção!</strong></p>
                <p>Se você é profissional diarista e quer se cadastrar, clique no botão abaixo.</p>
                <button onClick={souProfissional} className="btn btn-danger rounded-pill">sou diarista</button>
              </div>
            </div>
          </Slide>
          <Slide direction="left" in={toogles.step === 'payment'} mountOnEnter unmountOnExit>
            <div className="text-center">
              <p>É tudo instantâneo, rápido e seguro - o acesso a plataforma é liberado imediatamente.</p>
              <p className="fw-bold barlow text-uppercase m-0">Aponte sua câmera aqui!</p>
              <img alt="gerando qr-code pix" src={pix?.generate?.imagemQrcode} />
              <p>ou</p>
              <button type="button" onClick={() => copyClipboardConfirmationSaldo(pix?.generate?.qrcode)} className={`w-100 btn btn-block ${toogles.copyText ? 'btn-success' : 'btn-outline-success'}`}>
                {!toogles.copyText && <><CheckCircleOutlineIcon /> <small className="d-block">copiar código do pix</small></>}
                {toogles.copyText && <><CheckCircleIcon /> <small className="d-block">copiado</small></>}
              </button>
            </div>
          </Slide>
          <Slide direction="left" in={toogles.step === 'error'} mountOnEnter unmountOnExit>
            <div className="text-center">
              <p className="text-danger barlow fw-bold">Olá {props?.user?.nome}, infelizmente ocorreu um erro na tentativa de efetuar o pagamento.</p>
              <button type="button" onClick={() => handleToggle('step', 'payment')} className="text-uppercase barlow btn-primary btn px-4 fw-bold w-100">Tentar Novamente</button>
              <small>suas informações já estão preenchidas</small>
            </div>
          </Slide>
          <Slide direction="left" in={toogles.step === 'completed'} mountOnEnter unmountOnExit>
            <div className="text-center">
              <img alt="Crédito adquiridos com sucesso" src="/assets/images/icons/star-success.png" className="mb-2" />
              <h3 className="barlow fw-bold">Maravilha!</h3>
              <p className="lead text-muted fw-bold">Sua conta foi liberada e você já pode requisitar os telefones novamente!</p>
              <p className="lead">Obrigado pela confiança e a oportunidade de melhorarmos a Odete.com.br cada vez mais!</p>
            </div>
          </Slide>
        </DialogContent>
        {
          toogles.step === 'information' &&
            <DialogActions>
              <div className="w-100">
                <button type="submit" className="btn btn-primary w-100 text-uppercase barlow fw-bold btn-lg">liberar acesso</button>
              </div>
            </DialogActions>
        }
        {
          toogles.step === 'formulario' && 
            <DialogActions>
              <div className="flex-fill d-flex gap-2">
                <button type="button" onClick={handleCloseNavigate} className="w-100 btn btn-outline-secondary btn-lg btn-block text-uppercase fw-bold barlow">Cancelar</button>
                <button type="submit" className="w-100 btn btn-primary btn-lg btn-block text-uppercase fw-bold barlow">Próximo</button>
              </div>
            </DialogActions> 
        }
        {
          toogles.step === 'confirmation' && 
            <DialogActions>
              <div className="flex-fill d-flex gap-2">
                <button type="button" onClick={() => handleToggle('step', 'formulario')} className="w-100 btn btn-outline-secondary btn-lg btn-block text-uppercase fw-bold barlow">Voltar</button>
                <button type="submit" className="w-100 btn btn-primary btn-lg btn-block text-uppercase fw-bold barlow">Finalizar</button>
              </div>
            </DialogActions> 
        }
        {
          toogles.step === 'completed' && 
            <DialogActions>
                  <div className="flex-fill d-flex gap-2">
                    <div className="col">
                      <button type="button" onClick={handleCloseNavigate} className="w-100 btn btn-primary btn-lg btn-block text-uppercase fw-bold barlow">FECHAR E NAVEGAR</button>
                    </div>                        
                  </div>
            </DialogActions> 
        }
      </form>
    </Drawer>}</>
  );
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user
  }
);

export default connect(mapStateToProps, actions)(Index);