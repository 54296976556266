import Router from 'next/router';
import { AUTHENTICATE, DEAUTHENTICATE, GETUSER, FINDMAKER, PUBLISH } from '../types';
import Service from '../../services/service';
import { setCookie, removeCookie, getCookie } from '../../utils/cookie';
import "isomorphic-fetch";
import firebase from "../../src/firebase/fire.js";

// gets token from the api and stores it in the redux store and in cookie
const authenticateByUID = (uid, user) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {

        removeCookie('token');

        var data = user;

        var resp = await fetch(`https://ipinfo.io?token=b1fb2fc176cee3`).then(resp => resp.json()).catch(e => false);
        if(resp){

          var loc = resp.loc.split(',');
          var region = Service.region;

          data.loc = [loc[1], loc[0]];
          data.cidade = resp.city;
          data.cep = resp?.postal?.replace(/\D/g,'');
          data.uf = region[resp.region];
          data.ip = resp.ip;
        }

        Service.saveSocialUser(uid, data).then(async (response) => {
          if(response && response && response.token){

            await setCookie('token', response.token);
            await dispatch({type: GETUSER, payload: response.message});
            await dispatch({type: AUTHENTICATE, payload: response.token});
            resolve(response);
          }else{

            if(response.message){
              alert(response.message);
            }else{
              alert('Ops, verifique novamente seu e-mail ou sua senha ;)');
              dispatch({type: DEAUTHENTICATE});
            }

            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
          alert('Não foi possível se conectar ao servidor' + err);
        });







    })
  };
};

// coloca no redux as diaristas selecionadas no disparo
const publish = (data) => {

  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      dispatch({type: PUBLISH, payload: data});
      resolve(data)
    })

  };
};

// simplismente coloca no redux os dados da resposta do find por colaboradores
const findMaker = (data) => {
  
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      dispatch({type: FINDMAKER, payload: data});
      resolve(data)
    })

  };
};

const authenticate = ({ email, senha }, type) => {
  if (type !== 'login' && type !== 'signup') {
    throw new Error('Wrong API call!');
  }
  return (dispatch) => {
    


    return new Promise((resolve, reject) => {
      
      removeCookie('token');

      Service.login({ 'email': email, 'senha': senha }).then(response => {
          if(response && response.success){
            setCookie('token', response.token);
            dispatch({type: AUTHENTICATE, payload: response.token});
            resolve(response);
          }else{
            resolve(response);
          }
      })
      .catch((err) => {
        console.log(err);
        alert('Não foi possível se conectar ao servidor');
        reject(err);
      });
    })
  };
};

const getUser = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {

      Service.getUser().then((response) => {

          if(response && response.success === false){
            //dispatch({type: DEAUTHENTICATE});
            reject(response);  
          }else {
            setCookie('user', response);
            dispatch({type: GETUSER, payload: response});
            resolve(response)
          }

        })
        .catch((err) => {
          console.log('deu erro indo consultar o Redux => getUser =>', err)
          alert('Não foi possível se conectar ao servidor');
          reject(err);
        });
    })
  };
};

// gets the token from the cookie and saves it in the store
const reauthenticate = (token) => {
  return (dispatch) => {
    dispatch({type: AUTHENTICATE, payload: token});
  };
};

// removing the token
const deauthenticate = () => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      
      firebase?.auth()?.signOut();

      removeCookie('token');
      removeCookie('user');
      removeCookie('TOKEN_QGX');
      dispatch({type: DEAUTHENTICATE});
      resolve(true)
    })
  };
};

export default {
  authenticate,
  reauthenticate,
  deauthenticate,
  getUser,
  authenticateByUID,
  findMaker,
  publish
};