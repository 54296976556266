import { memo } from 'react';

import Rating from "./rating";
import ShareIcon from '@material-ui/icons/Share';
import Service from "../services/service";
import Image from "./image";
import AvatarGroup from "./avatarGroup";

const PerfilSolicitacoes = (props) => {

  let profile = props.profile;
  let atendimentos = profile?.solicitacoes?.filter(item => item.matchColaborador && item.matchContratante)

  return (
    <div className="col-12 mb-4 px-4">
      <h3 className="display-4" id="atendimentos">{atendimentos?.length} atendimentos...</h3>
      {(profile?.next && profile?.next) > 0 && <p className="text-muted text-uppercase"><strong>{profile?.next} pessoas já olharam meu telefone</strong></p>}
      
      <div className="mb-4">
        <AvatarGroup max={4}>
          {
            profile?.solicitacoes?.map(item => {
              return (<Image key={item._id} src={Service.parseURLImage(item.contratante)} alt={item.contratante?.nome} />)
            })
          }
        </AvatarGroup>
      </div>


      {
        profile?.solicitacoes && profile?.solicitacoes?.filter(item => item?.contratante)?.map(item => {
        return (<div key={item._id} className="mb-5 pb-5 border-bottom position-relative">
            

            {item?.messageContratante &&
              <a className="position-absolute top-0 end-0" href={`https://api.odete.com.br/shareSolicitacao/${item?._id}`} target="_blank">
                <ShareIcon titleAccess="Compartilhar" />
              </a>
            }

            <div className="d-flex align-items-start">
              <Image src={Service.parseURLImage(item?.contratante)} height="40" width="40" alt={item?.contratante?.nome} classNameFigure="me-2" className="rounded-circle me-2 image-perfil" /> 
              <div className="flex-fill">
                <small className="text-muted">
                  {Service.formatDate(item?.createdAt, 'dd/MM/yyyy')}
                </small>
                
                <div className="d-flex align-items-center">
                  <span className="fw-bold me-1 barlow">
                    {item?.contratante?.nome?.toUpperCase()}
                    </span>
                  <Rating className="d-flex" value={item?.avaliacaodoContratante?.rating} readOnly precision={0.1} size="small" />
                </div>
                
                {(item.solicitacaoFechadaPor && item.solicitacaoFechadaPor === item?.contratante?._id) && <div className="mt-3 text-dark alert alert-warning d-block-inline text-break-spaces">{item.solicitacaoMotivo}</div>}
                
                {item?.messageContratante && <p className="text-dark text-break-spaces p-0 m-0">{item?.messageContratante}</p>}
                
                {
                  item?.avaliacaodoContratante?.criterios?.length > 0 && 
                  <div className="mt-3">
                    <p className="barlow bg-dark text-white p-1 lh">Como {item?.contratante?.nome} avaliou {profile?.nome}?</p>
                    <div className="row flex-column">
                      {
                        item.avaliacaodoContratante.criterios.map((aval, index) => {
                          return (
                            <div className="mb-2 col flex-row d-flex gap-2 align-items-center" key={aval}>
                              <Rating className="d-flex" value={item.avaliacaodoContratante.stars[index]} readOnly precision={0.1} size="small" />
                              <small className="m-0 d-block text-truncate barlow text-muted">{aval}</small>
                            </div>)
                        })
                      }
                    </div>
                  </div>
                }

                {(!item?.messageContratante && item.solicitacaoFechadaPor && item.solicitacaoFechadaPor === profile?._id) && <div className="mt-3 text-dark alert alert-warning d-flex gap-2 text-break-spaces"><Image width="24" height="24" alt={profile?.nome} className="rounded-circle image-perfil" src={Service.parseURLImage(profile)} /> {item.solicitacaoMotivo}</div>}

                {
                  item.messageColaborador && 
                  <div className="mt-4 d-flex">
                    <Image width="40" height="40" alt={profile?.nome} className="rounded-circle" src={Service.parseURLImage(profile)} />
                    <div className="m-0 ms-2 flex-fill">
                      <div className="d-flex align-items-center">
                        <span className="fw-bold me-1 barlow">{profile?.nome?.toUpperCase()}</span>
                        <Rating className="d-flex" value={item?.avaliacaodoColaborador?.rating} readOnly precision={0.1} size="small" />
                      </div>
                      <p className="text-muted m-0">
                        {item.messageColaborador}
                      </p>
                      
                      

                      {
                        item?.avaliacaodoColaborador?.criterios?.length && 
                        <div className="mt-3">
                          <p className="barlow bg-dark text-white p-1 lh">Como {profile?.nome} avaliou {item?.contratante?.nome}?</p>
                          <div className="row flex-column">
                            {
                              item.avaliacaodoColaborador.criterios.map((aval, index) => {
                                return (
                                  <div className="mb-2 flex-row col d-flex align-items-center gap-2" key={aval}>
                                    <Rating className="d-flex" value={item.avaliacaodoColaborador.stars[index]} readOnly precision={0.1} size="small" />
                                    <small className="m-0 d-block text-truncate barlow text-muted barlow">{aval}</small>
                                  </div>)
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>)
        })
      }
    </div>
  )
};

export default memo(PerfilSolicitacoes);